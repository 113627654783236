var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-row',{staticClass:"pa-2",attrs:{"wrap":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('gmap-map',{key:_vm.mapKey,ref:"mapRef",staticStyle:{"height":"40vh","width":"100%"},attrs:{"zoom":_vm.zoom,"options":{
                                            styles: _vm.mapStyle,
                                            disableDefaultUI: true
                                        },"center":_vm.mapCenter}},_vm._l((_vm.shipments),function(marker){return _c('gmap-marker',{key:marker.id,attrs:{"alignment":"center","position":{
                                                    lng: marker.location.lon,
                                                    lat: marker.location.lat
                                                }}})}),1)],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6","lg":"4"}},[_c('v-card',{staticClass:"pa-1",staticStyle:{"background":"rgba(255, 255, 255, 0.05)","backdrop-filter":"blur(12px)","border":"1px solid rgb(255, 255, 255, 0.2)"},attrs:{"shaped":"","dark":_vm.$store.state.darkMode}},[_c('v-card-title',[_c('v-icon',{staticClass:"mr-1"},[_vm._v("notifications")]),_vm._v(" Notifications Hub ")],1),_c('v-card-text',[_c('v-list',{staticStyle:{"background-color":"transparent","padding":"0px","margin":"0px","backdrop-filter":"blur(12px)"},attrs:{"dense":""}},[_vm._l((10),function(i){return [_c('v-hover',{key:i,scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-list-item',{staticStyle:{"background-color":"transparent","height":"30px","font-size":"14px","border-top":"1px solid rgba(255, 255, 255, 0.1)"},attrs:{"hover":"","dense":""}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Document #127648"+_vm._s(i)+" ")]),_c('v-list-item-subtitle',[_vm._v(" Review Required ")])],1),_c('v-list-item-action',[(hover)?_c('v-btn',{staticClass:"dotted",attrs:{"icon":"","small":""}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("check")])],1):_vm._e()],1)],1)]}}],null,true)})]})],2)],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6","lg":"4"}},[_c('v-card',{staticStyle:{"height":"500px","width":"500px","background":"rgba(255,255,255,0.05)","backdrop-filter":"blur(8px)","border":"1px solid rgb(255,255,255,0.2)"},attrs:{"shaped":"","dark":""}},[_c('v-card-text',{staticClass:"text-center",staticStyle:{"height":"500px","width":"500px"}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }