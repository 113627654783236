<template>
<div>
    <v-row wrap class="pa-2">
        <v-col cols="12">
            <gmap-map :zoom="zoom" :key="mapKey" :options="{
                                            styles: mapStyle,
                                            disableDefaultUI: true
                                        }" :center="mapCenter" ref="mapRef" style="height: 40vh; width: 100%">
                <gmap-marker alignment="center" v-for="marker in shipments" :key="marker.id" :position="{
                                                    lng: marker.location.lon,
                                                    lat: marker.location.lat
                                                }">
                </gmap-marker>
            </gmap-map>
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="4">
            <v-card shaped :dark="$store.state.darkMode" class="pa-1" style="background: rgba(255, 255, 255, 0.05); backdrop-filter: blur(12px); border: 1px solid rgb(255, 255, 255, 0.2);">
                <v-card-title>
                    <v-icon class="mr-1">notifications</v-icon> Notifications Hub
                </v-card-title>
                <v-card-text>
                    <v-list dense style="background-color: transparent;padding: 0px; margin:0px; backdrop-filter: blur(12px);">
                        <template v-for="i in 10">
                            <v-hover v-slot="{ hover }" :key="i">
                                <v-list-item hover dense style="background-color: transparent; height: 30px; font-size: 14px; border-top: 1px solid rgba(255, 255, 255, 0.1)">
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            Document #127648{{i}}
                                        </v-list-item-title>
                                        <v-list-item-subtitle>
                                            Review Required
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                    <v-list-item-action>
                                        <v-btn icon small v-if="hover" class="dotted">
                                            <v-icon small>check</v-icon>
                                        </v-btn>
                                    </v-list-item-action>
                                </v-list-item>
                            </v-hover>
                        </template>

                    </v-list>
                </v-card-text>
            </v-card>
        </v-col>

        <v-col cols="12" sm="12" md="6" lg="4">
            <v-card shaped dark style="height: 500px; width: 500px;background: rgba(255,255,255,0.05);backdrop-filter: blur(8px);border: 1px solid rgb(255,255,255,0.2);">
                <v-card-text class="text-center" style="height: 500px; width: 500px;">
                    <!-- <LineMarker id="3" height="95%" width="95%" /> -->
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>

</div>
</template>

<script>
// import Keyboard from '@/components/Charts/Keyboard'
// import LineMarker from '@/components/Charts/LineMarker'
// import LineArea from '@/components/Charts/LineArea'

export default {
    components: {
        // Keyboard, 
        // LineMarker,
        // LineArea 
    },
    data: () => ({
        mapCenter: {
            lat: 8.7832,
            lng: 39.5085
        },
        mapKey: 0,
        mapStyle: [{
                elementType: "geometry",
                stylers: [{
                    color: "#f5f5f5",
                }, ],
            },
            {
                elementType: "labels.icon",
                stylers: [{
                    visibility: "off",
                }, ],
            },
            {
                elementType: "labels.text",
                stylers: [{
                    color: "#616161",
                    visibility: "off",
                }, ],
            },
            {
                elementType: "labels.text.fill",
                stylers: [{
                    color: "#616161",
                    visibility: "off",
                }, ],
            },
            {
                elementType: "labels.text.stroke",
                stylers: [{
                    color: "#f5f5f5",
                }, ],
            },
            {
                featureType: "administrative",
                elementType: "geometry",
                stylers: [{
                    visibility: "off",
                }, ],
            },
            {
                featureType: "administrative.land_parcel",
                elementType: "labels.text.fill",
                stylers: [{
                    color: "#bdbdbd",
                }, ],
            },
            {
                featureType: "poi",
                stylers: [{
                    visibility: "off",
                }, ],
            },
            {
                featureType: "poi",
                elementType: "geometry",
                stylers: [{
                    color: "#eeeeee",
                }, ],
            },
            {
                featureType: "poi",
                elementType: "labels.text.fill",
                stylers: [{
                    color: "#757575",
                }, ],
            },
            {
                featureType: "poi.park",
                elementType: "geometry",
                stylers: [{
                    color: "#e5e5e5",
                }, ],
            },
            {
                featureType: "poi.park",
                elementType: "labels.text.fill",
                stylers: [{
                    color: "#9e9e9e",
                }, ],
            },
            {
                featureType: "road",
                elementType: "geometry",
                stylers: [{
                    color: "#ffffff",
                }, ],
            },
            {
                featureType: "road",
                elementType: "labels.icon",
                stylers: [{
                    visibility: "off",
                }, ],
            },
            {
                featureType: "road.arterial",
                elementType: "labels.text.fill",
                stylers: [{
                    color: "#757575",
                }, ],
            },
            {
                featureType: "road.highway",
                elementType: "geometry",
                stylers: [{
                    color: "#c1d6e0",
                }, ],
            },
            {
                featureType: "road.highway",
                elementType: "labels.text.fill",
                stylers: [{
                    color: "#616161",
                }, ],
            },
            {
                featureType: "road.local",
                elementType: "labels.text.fill",
                stylers: [{
                    color: "#9e9e9e",
                }, ],
            },
            {
                featureType: "transit",
                stylers: [{
                    visibility: "off",
                }, ],
            },
            {
                featureType: "transit.line",
                elementType: "geometry",
                stylers: [{
                    color: "#e5e5e5",
                }, ],
            },
            {
                featureType: "transit.station",
                elementType: "geometry",
                stylers: [{
                    color: "#eeeeee",
                }, ],
            },
            {
                featureType: "water",
                elementType: "geometry",
                stylers: [{
                    color: "#9cb0ba",
                }, ],
            },
            {
                featureType: "water",
                elementType: "labels.text.fill",
                stylers: [{
                    color: "#ffffff",
                }, ],
            },
        ],
        shipments: [],
        textMode: true,
        zoom: 2
    }),
    created() {
        this.getActiveShipments()
    },
    methods: {
        async getActiveShipments() {
            this.shipments = await this.$API.getShipmentMap()
        }
    }
}
</script>

<style>
.background-app {
    height: 95vh;
    background-image: url("/background2.jpg");
    /* background: linear-gradient(90deg, rgba(121,24,9,1) 35%, rgba(2,42,198,1) 100%); */
    background-size: cover;
    background-repeat: no-repeat;
}

.chart-container {
    position: relative;
    width: 100%;
    height: calc(100vh - 84px);
}

.landing {
    height: 95vh;
    color: rgba(255, 255, 255, 0.5);
    font-family: sans-serif;
    padding: 10px;
}

.glass {
    /* width: 320px; */
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(8px);
    border: 1px solid rgb(255, 255, 255, 0.2);
}

.dotted {
    border-color: white;
    border-style: dotted;
}
</style>
